import React, { useState } from "react"
import { Link } from "gatsby"

import OpenIcon from "../../assets/icons/Open.jsx"


function TertiaryMenuItem({ menuItem, parentPath }) {
  const [open, setOpen] = useState(false)

  const setParentClass = ({ isPartiallyCurrent, isCurrent }) => {
    console.log(isPartiallyCurrent, isCurrent)
    if (isPartiallyCurrent && open === false) {
      setOpen(true)
    }
    let classList = "link"
    classList += isPartiallyCurrent ? " partial" : ""
    classList += isCurrent ? " active" : ""
    return isPartiallyCurrent ? { className: classList } : {}
  }

  const fullItemPath = `${parentPath}/${menuItem.path}`

  return (
    <div className="tertiary-wrap">
      <div className="link-wrap">
        <Link
          className="link"
          getProps={setParentClass}
          to={fullItemPath}
        >
          <div className="link-label">{menuItem.title}</div>
        </Link>
        {menuItem.items.length > 0 && (
          <div
            className={open ? "toggle-icon open" : "toggle-icon"}
            onClick={() => setOpen(!open)}
          >
            <OpenIcon />
          </div>
        )}
      </div>

      {menuItem.items && (
        <div className={open ? "nav-drop-down nav-quaternary-level open" : "nav-drop-down nav-quaternary-level"}>
          {menuItem.items &&
            menuItem.items.map(
              (quaternaryLevel, key) => {
                return (
                  <div className="quaternary-wrap" key={key}>
                    <div className="link-wrap">
                      <Link
                        className="link"
                        to={`${fullItemPath}/${quaternaryLevel.path}`}
                        activeClassName="active"
                      >
                        {quaternaryLevel.title}
                      </Link>
                    </div>
                  </div>
                )
              }
            )}
        </div>
      )}
    </div>
  )
         
}

export default TertiaryMenuItem
