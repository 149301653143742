import React from "react";

function GettingStartedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.51 28.62">
      <path d="M10.93 19.87a.155.155 0 00-.21.02l-1.89 2.33c-.05.06-.04.16.02.21l.78.63c.03.02.06.03.09.03h.02c.04 0 .08-.02.1-.05l1.89-2.33c.05-.06.04-.16-.02-.21l-.78-.63zm-1.5-1.16a.11.11 0 00-.06-.1l-.77-.63c-.07-.05-.16-.05-.21.02l-3.15 3.88c-.05.06-.04.16.02.21l.78.63c.03.02.06.03.09.03h.02c.04 0 .08-.02.1-.05l3.15-3.89c.02-.02.03-.06.03-.1zm6.82-7.01c.58.47 1.28.7 1.98.7.92 0 1.83-.4 2.45-1.17 1.09-1.35.88-3.34-.47-4.43-.65-.53-1.47-.77-2.31-.68-.84.09-1.59.5-2.12 1.15-.53.65-.77 1.47-.69 2.31s.51 1.59 1.16 2.12zm.55-3.61c.31-.38.75-.62 1.24-.68.07-.01.13-.01.2-.01.42 0 .83.14 1.16.41.38.31.62.75.68 1.24s-.09.97-.4 1.36c-.31.38-.75.62-1.25.68-.49.05-.97-.09-1.36-.4-.79-.64-.91-1.81-.27-2.6z"></path>
      <path d="M25.62 1.12l-.2-.62a.138.138 0 00-.13-.1l-.97-.1c-4.66-.37-9.72 2.08-13.21 6.39-.98 1.21-1.81 2.53-2.45 3.92l-.16.35-1.63-.36c-.05-.01-.1 0-.13.04L.33 16.59c-.03.03-.05.07-.05.11s.02.08.06.11l2.04 1.65c.03.03.08.04.12.03l5.75-1.14c.85.32 1.64.77 2.35 1.34.69.56 1.28 1.22 1.76 1.96l.1 5.9c0 .04.02.09.06.11l2.04 1.65c.03.02.06.03.09.03h.03c.04-.01.08-.03.1-.07l4.49-7.51c.03-.04.03-.09.01-.14l-.68-1.49.26-.19c1.24-.93 2.38-2.02 3.38-3.26 3.58-4.44 4.88-10.02 3.38-14.56zM13.74 26l-.06-4.43.21-.06c1.23-.38 2.44-.93 3.6-1.63l.34.75-3.5 5.85-.59-.48zm4.27-23.17l6.55 5.3c-.55 2.38-1.71 4.71-3.35 6.74-2.06 2.55-4.76 4.44-7.6 5.35a9.403 9.403 0 00-2.2-2.54 9.744 9.744 0 00-2.94-1.63c.29-2.97 1.59-6 3.65-8.54 1.64-2.03 3.68-3.65 5.89-4.68zm6.82 3.84l-5.44-4.4c1.62-.56 3.27-.79 4.81-.67l.22.02c.49 1.53.63 3.27.41 5.05zM8.01 12.19l-.08.22a15.88 15.88 0 00-.78 3.65l-.02.2-4.37.87-.59-.48 5-4.64.84.18z"></path>
    </svg>
  );
}

export default GettingStartedIcon;