import React, { useState } from "react"
import { Link } from "gatsby"

import OpenIcon from "../../assets/icons/Open.jsx"
import TertiaryMenuItem from "./TertiaryMenuItem.js"


function SecondaryMenuItem({ menuItem, parentPath }) {
  const [open, setOpen] = useState(false)


  const setParentClass = ({ isPartiallyCurrent, isCurrent }) => {
    if (isPartiallyCurrent && open === false) {
      setOpen(true)
    }
    let classList = "link"
    classList += isPartiallyCurrent ? " partial" : ""
    classList += isCurrent ? " active" : ""
    return isPartiallyCurrent ? { className: classList } : {}
  }

  const fullItemPath = `/${parentPath}/${menuItem.path}`

  return (
    <div className="secondary-wrap">
      <div className="link-wrap">
        <Link
          className="link"
          getProps={setParentClass}
          to={fullItemPath}
        >
          <div className="link-label">{menuItem.title}</div>
        </Link>
        {menuItem.items.length > 0 && (
          <div
            className={open ? "toggle-icon open" : "toggle-icon"}
            onClick={() => setOpen(!open)}
          >
            <OpenIcon />
          </div>
        )}
      </div>
      
      <div className={open ? "nav-drop-down nav-tertiary-level open" : "nav-drop-down nav-tertiary-level"}>
        {menuItem.items.map((tertiaryLevel, key) => {
          return (
            <TertiaryMenuItem menuItem={tertiaryLevel} parentPath={fullItemPath} key={key} />
          )
        })}
      </div>
    </div>
  )
         
}

export default SecondaryMenuItem
