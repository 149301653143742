import React from "react";

function OpenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5.5">
      <path d="M.5 0L5 4.5 9.5 0l.5.5-5 5-5-5L.5 0z"></path>
    </svg>
  );
}

export default OpenIcon;
