import React, { Fragment, useState, useRef, useEffect } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import OpenIcon from "../assets/icons/Open"
import Seo from "../components/seo"

const QuickLinks = () => {
  const quicklinkNode = useRef()

  const [open, setOpen] = useState(false)

  const handleClick = e => {
    if (quicklinkNode.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  return (
    <StaticQuery
      query={staticQuery}
      render={data => (
        <Fragment>
          <div className="quicklinks" ref={quicklinkNode}>
            <button className="dropdown-toggler" onClick={e => setOpen(!open)}>
              <h5>Quick Links</h5>
              <div
                className="change-arrow"
                style={
                  open
                    ? { transform: "rotate(-180deg)" }
                    : { transform: "rotate(0deg)" }
                }
              >
                <OpenIcon />
              </div>
            </button>
            {open && (
              <div className="links-wrap">
                {data.quickLinksInfo.edges[0].node.link.map((linkItem, key) => {
                  const url = linkItem.link_url.startsWith("/") ? linkItem.link_url : `/${linkItem.link_url}`
                  return (
                    <Link
                      className="link-item"
                      to={url}
                      key={key}
                    >
                      <h4>{linkItem.link_text}</h4>
                    </Link>
                  )
                })}
              </div>
            )}
          </div>
        </Fragment>
      )}
    />
  )
}
export default QuickLinks

export const staticQuery = graphql`
  {
    quickLinksInfo: allStrapiQuickLinks {
      edges {
        node {
          link {
            link_text
            link_url
          }
        }
      }
    }
  }
`
