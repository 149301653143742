import * as React from "react"

import MpiIcon from "../assets/icons/MpiIcon"
import NzCustomsIcon from "../assets/icons/NzCustomsLogo"
import TswToolBox from "../assets/icons/TswToolBoxLogo"

const Footer = () => (
  <header id="site-footer">
    <div className="footer-wrap">
      <div className="start">©Copyright {new Date().getFullYear()}, TSW Toolbox </div>
      <div className="end">
        <div className="customs">
          <NzCustomsIcon />
        </div>
        <div className="mpi">
          <MpiIcon />
        </div>
        <div className="toolbox">
          <TswToolBox />
        </div>
      </div>
    </div> 
  </header>
)


export default Footer

