import React from "react";

function ChangelogIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 13">
      <path d="M12.286 8.203c0 .651-.514 1.18-1.147 1.18h-9.02l1.51-1.554-.54-.555L.924 9.498a.403.403 0 000 .556l2.163 2.224.54-.556-1.51-1.553h9.02c1.057-.003 1.91-.88 1.913-1.966V6.63h-.765v1.573zM1.578 5.057c0-.652.514-1.18 1.147-1.18h9.02l-1.51 1.553.54.556L12.94 3.76a.403.403 0 000-.555L10.775.98l-.54.556 1.51 1.553h-9.02C1.67 3.093.815 3.97.813 5.057V6.63h.765V5.057z"></path>
    </svg>
  );
}

export default ChangelogIcon;
