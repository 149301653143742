import React from "react";

function ContactIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.5 11.4">
      <path d="M1.6 0C.7 0 0 .7 0 1.6v8.2c0 .9.7 1.6 1.6 1.6h13.3c.9 0 1.6-.7 1.6-1.6V1.6c0-.9-.7-1.6-1.6-1.6H1.6zm0 .8h13.3c.1 0 .2 0 .3.1l-6.5 6c-.3.3-.7.3-1 0L1.2.9c.2-.1.3-.1.4-.1zm-.8.7l4.6 4.3-4.5 4.4c-.1-.1-.1-.3-.1-.4V1.5c0 .1 0 .1 0 0zm14.9 0c0 .1 0 .1 0 0v8.3c0 .1 0 .3-.1.4l-4.5-4.4 4.6-4.3zm-5.2 4.8l4.4 4.3H1.6L6 6.3l1.2 1.1c.6.6 1.5.6 2.1.1l1.2-1.2z"></path>
    </svg>
  );
}

export default ContactIcon;
