import React from "react";

function GlossaryIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.07 16.09">
      <path d="M12.07 11.87V1.34C12.07.6 11.46 0 10.71 0H2.74c-.41 0-.8.09-1.17.26C.61.71 0 1.67 0 2.71v11.62c0 .97.79 1.75 1.77 1.75H11c.25 0 .49-.12.64-.32.16-.21.2-.49.11-.74-.09-.24-.15-.48-.19-.73-.09-.6.01-1.17.07-1.45.27-.23.44-.58.44-.97zM.64 2.71c0-.62.28-1.2.75-1.59v11.51c-.28.06-.54.19-.75.36V2.71zM11 15.46H1.77c-.63 0-1.14-.5-1.14-1.12 0-.6.47-1.09 1.08-1.12h8.99c.07 0 .14-.01.21-.02-.03.23-.05.49-.03.77H3.7a.32.32 0 00-.32.31c0 .17.14.31.32.31h7.27c.04.22.11.44.18.65.04.11-.05.22-.15.22zm.43-3.59c0 .39-.32.71-.72.71H2.02V.75c.23-.08.47-.13.72-.13h7.97c.4 0 .72.32.72.71v10.54z"></path>
      <path d="M3.17 5.03c.17.06.35-.02.41-.18l.19-.5h1.31l.19.5c.07.16.27.24.43.17.15-.07.22-.25.16-.4L4.79 1.8v-.01a.395.395 0 00-.36-.24c-.16 0-.3.09-.36.24v.01L2.98 4.62c-.06.16.02.34.19.41zm1.26-2.39l.41 1.07h-.82l.41-1.07zm5.28 8.29H8.16l1.72-2.46c.07-.1.08-.22.02-.32a.316.316 0 00-.28-.17H7.61a.32.32 0 00-.32.31c0 .17.14.31.32.31h1.4l-1.72 2.46c-.07.1-.08.22-.02.32.05.1.16.17.28.17H9.7c.17 0 .32-.14.32-.31 0-.17-.14-.31-.31-.31zM6.47 6.79c.18 0 .32-.14.32-.31 0-.17-.14-.31-.32-.31s-.32.14-.32.31c0 .17.15.31.32.31z"></path>
    </svg>
  );
}

export default GlossaryIcon;