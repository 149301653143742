import React from "react";

function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <path d="M5.389 5.997L.913 1.522a.432.432 0 01-.01-.614.432.432 0 01.614-.01l.01.01 4.476 4.475L10.479.908a.434.434 0 01.613.614L6.617 5.997l4.475 4.476a.432.432 0 010 .614.432.432 0 01-.614 0L6.004 6.611l-4.476 4.476a.434.434 0 01-.614-.614L5.39 5.997z"></path>
    </svg>
  );
}

export default CloseIcon;
