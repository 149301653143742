import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import OpenIcon from "../assets/icons/Open"
import StatusItem from "../components/statusItem"
import Quicklinks from "./quicklinks"
import MpiIcon from "../assets/icons/MpiIcon"
import RedIcon from "../assets/icons/StatusRed"

const Header = () => {
  let ui = ""
  let b2b = ""
  let notifications = ""

  // Client-side Runtime Data Fetching
  const [systemInfo, setSystemInfo] = useState({
    systemStatus: null,
  })
  useEffect(() => {
    // get Status page info
    fetch(`${process.env.GATSBY_STRAPI_API_URL}/status`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        if (resultData) {
          ui = resultData.ui
          b2b = resultData.b2b
          notifications = resultData.notifications

          if (
            ui === "Operational" &&
            b2b === "Operational" &&
            notifications === "Operational"
          ) {
            setSystemInfo({
              systemStatus: "Operational",
            })
          } else if (
            ui === "Some_Problems" ||
            b2b === "Some_Problems" ||
            notifications === "Some_Problems"
          ) {
            setSystemInfo({
              systemStatus: "Some_Problems",
            })
          } else if (
            ui === "Down" ||
            b2b === "Down" ||
            notifications === "Down"
          ) {
            setSystemInfo({
              systemStatus: "Some_Problems",
            })
          }

          if (ui === "Down" && b2b === "Down" && notifications === "Down") {
            setSystemInfo({
              systemStatus: "Down",
            })
          }
        }
      })
  }, [])
  return (
    <header id="site-header">
      <div className="header-link">
        <Quicklinks />
        <Link to="/status" className="status">
          Status
          {systemInfo.systemStatus && (
            <StatusItem
              statusMessage={systemInfo.systemMessage}
              statusItem={systemInfo.systemStatus}
            ></StatusItem>
          )}
        </Link>
      </div>
    </header>
  )
}

export default Header
