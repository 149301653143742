import React from "react";

function MessagesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.26 13.96">
      <path d="M12.99 0H2.25C1.01 0 0 1.01 0 2.25v7.98c0 1.24 1.01 2.25 2.25 2.25h8.43l2.05 1.42c.06.04.14.06.22.06.06 0 .12-.02.18-.04.12-.06.22-.2.22-.34l.04-1.13a2.258 2.258 0 001.88-2.23V2.25C15.24 1.01 14.24 0 12.99 0zm1.46 10.23c0 .81-.65 1.48-1.48 1.48-.22 0-.38.16-.39.38l-.02.77L11 11.79a.403.403 0 00-.22-.06H2.25c-.81 0-1.48-.65-1.48-1.48v-8c0-.81.65-1.48 1.48-1.48h10.74c.81 0 1.48.65 1.48 1.48l-.02 7.98z"></path>
      <path d="M3.73 7.23c.44 0 .79-.35.79-.79 0-.44-.35-.79-.79-.79-.43 0-.79.35-.79.79 0 .43.36.79.79.79zm3.97 0c.44 0 .79-.35.79-.79 0-.44-.35-.79-.79-.79-.43 0-.79.35-.79.79 0 .43.36.79.79.79zm3.97 0c.44 0 .79-.35.79-.79 0-.44-.35-.79-.79-.79-.44 0-.79.35-.79.79 0 .43.35.79.79.79z"></path>
    </svg>
  );
}

export default MessagesIcon;
