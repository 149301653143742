import React from "react";

function ResourcesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.06 15.1">
      <path d="M5.28 10.07l-3.31 3.31c-.16.16-.16.39 0 .55.08.08.2.12.28.12.08 0 .19-.04.28-.12l3.31-3.31c.16-.16.16-.39 0-.55-.17-.16-.41-.16-.56 0zm-.27-.82a.375.375 0 00-.55 0l-3.31 3.31c-.08.08-.12.17-.12.28s.04.2.12.28c.08.08.2.12.28.12.08 0 .19-.04.28-.12l3.3-3.32c.15-.15.15-.39 0-.55z"></path>
      <path d="M14.08 9.46c-.87-.9-2.14-1.23-3.32-.89L9.32 7.13l3.44-3.44h.66c.11 0 .19-.04.28-.12l1.23-1.23c.08-.08.12-.17.12-.28 0-.1 0-.22-.1-.28L13.32.12a.375.375 0 00-.55 0l-1.21 1.22a.35.35 0 00-.16.29v.66L7.95 5.76 6.51 4.32c.37-1.17.05-2.44-.83-3.32C4.84.16 3.62-.16 2.42.15a.45.45 0 00-.27.27c-.04.13 0 .28.11.4l1.07 1.07-.32 1.14-1.18.32L.79 2.28C.68 2.17.55 2.13.4 2.17a.45.45 0 00-.27.27C-.2 3.59.12 4.82.97 5.67c.87.87 2.17 1.19 3.32.83l1.44 1.44-.26.27a1.7 1.7 0 00-.74-.16c-.51 0-.97.2-1.3.56l-2.9 2.9c-.71.71-.71 1.89 0 2.63l.41.41c.35.35.83.56 1.3.56.51 0 .97-.2 1.3-.56l2.9-2.9c.36-.36.56-.83.56-1.3 0-.26-.05-.51-.16-.74l.26-.26 1.44 1.44c-.36 1.15-.04 2.42.83 3.32.62.62 1.46.97 2.35.97.34 0 .64-.04.91-.12a.45.45 0 00.27-.27c.04-.13 0-.28-.11-.39l-1.07-1.04.32-1.15 1.15-.32 1.07 1.07c.11.12.24.15.39.11a.45.45 0 00.27-.27c.34-1.15.01-2.39-.84-3.24zm-2.74 2.18l-.41 1.6c-.04.13 0 .28.11.39l.66.66c-.63-.01-1.25-.27-1.74-.74-.71-.71-.93-1.77-.56-2.72.04-.18.02-.32-.08-.42l-1.6-1.6L8.8 7.74l1.6 1.6c.12.12.29.16.43.08.94-.37 1.99-.15 2.7.56.43.43.7 1.06.73 1.71l-.65-.65a.363.363 0 00-.39-.11l-1.62.44c-.11.03-.22.15-.26.27zm1.27-8.74c-.11 0-.19.04-.28.12L6.4 8.98l-.27-.27 5.93-5.96c.08-.08.12-.2.12-.28v-.66l.84-.84 1.09 1.09-.84.84h-.66zm-8.86.56l.44-1.6c.04-.13 0-.28-.11-.4L3.41.81c.63.01 1.25.27 1.74.74.71.71.93 1.77.57 2.7-.11.23-.11.23 1.02 1.37l.66.66-1.09 1.09L4.68 5.8c-.12-.13-.28-.16-.42-.08-.93.37-1.99.15-2.7-.56-.48-.48-.74-1.1-.75-1.75l.66.66c.11.11.24.15.39.11l1.62-.44c.11-.04.22-.15.27-.28zm2.43 6.86c0 .33-.11.62-.29.77l-2.9 2.9c-.4.4-1.1.4-1.53 0l-.41-.41c-.21-.21-.32-.48-.32-.76 0-.29.11-.56.32-.76l2.9-2.9c.21-.21.48-.32.75-.32.29 0 .57.12.75.32l.41.41c.21.21.32.47.32.75z"></path>
    </svg>
  );
}

export default ResourcesIcon;
