import React from "react"

function MenuIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12">
      <path d="M.7 1.5h21.6c.4 0 .7-.3.7-.7s-.3-.8-.7-.8H.7C.3 0 0 .3 0 .7c0 .5.3.8.7.8zm21.6 3.7H.7c-.4 0-.7.4-.7.8s.3.7.7.7h21.6c.4 0 .7-.3.7-.7 0-.4-.3-.8-.7-.8zm-7.2 5.3H.7c-.4 0-.7.3-.7.7s.3.8.7.8h14.4c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8z" />
    </svg>
  )
}

export default MenuIcon
