import React, { useState } from "react"
import { Link } from "gatsby"

import GettingStartedIcon from "../../assets/icons/GettingStartedIcon"
import MessagesIcon from "../../assets/icons/Messages"
import OpenIcon from "../../assets/icons/Open.jsx"
import ResourcesIcon from "../../assets/icons/ResourcesIcon"
import ContactIcon from "../../assets/icons/ContactIcon"
import GlossaryIcon from "../../assets/icons/GlossaryIcon"
import ChanelogIcon from "../../assets/icons/ChangelogIcon"
import SecondaryMenuItem from "./SecondaryMenuItem"

function PrimaryMenuItem({ topLevel }) {
  const [open, setOpen] = useState(false)


  const setTopLevelClass = ({ isPartiallyCurrent, isCurrent }) => {
    if (isPartiallyCurrent && open === false) {
      setOpen(true)
    }
    let classList = "link"
    classList += isPartiallyCurrent ? " partial" : ""
    classList += isCurrent ? " active" : ""
    return isPartiallyCurrent ? { className: classList } : {}
  }

  return (
    <div className="nav-top-level" id={`parent-${topLevel.id}`}>
      <div className="link-wrap">
        <Link
          className="link"
          getProps={setTopLevelClass}
          to={`/${topLevel.path}`}
          // activeClassName="active"
        >
          <div className="icon">
            {topLevel && topLevel.title === "Getting Started" && (
              <GettingStartedIcon />
            )}
            {topLevel && topLevel.title === "Messages" && <MessagesIcon />}
            {topLevel && topLevel.title === "Resources" && <ResourcesIcon />}
            {topLevel && topLevel.title === "Contact" && <ContactIcon />}
            {topLevel && topLevel.title === "Glossary" && <GlossaryIcon />}
            {topLevel && topLevel.title === "Changelog" && <ChanelogIcon />}
          </div>
          <div className="link-label">{topLevel.title}</div>
        </Link>

        {topLevel.items.length > 0 && (
          <div
            className={open ? "toggle-icon open" : "toggle-icon"}
            onClick={() => setOpen(!open)}
          >
            <OpenIcon />
          </div>
        )}
      </div>
      {topLevel.items.length > 0 && (
        <div
          className={open ? "nav-drop-down nav-secondary-level open" : "nav-drop-down nav-secondary-level"}
          id="nav-secondary-level"
        >
          {topLevel.items.map((secondaryLevel, key) => {
            return <SecondaryMenuItem menuItem={secondaryLevel} parentPath={topLevel.path} key={key} />
          })}
        </div>
      )}
    </div>
  )
}

export default PrimaryMenuItem
