import React, { useEffect, useState, Fragment } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import PrimaryMenuItem from "./PrimaryMenuItem"
import TswToolBoxLogo from "../../assets/icons/TswToolBoxLogo.jsx"
import OpenIcon from "../../assets/icons/Open.jsx"
import MenuIcon from "../../assets/icons/Menu"
import CloseIcon from "../../assets/icons/Close"
import { isBrowser } from "../../utils/utils"
// import { ReactComponent as StatusYellow } from "../../assets/icons/status-yellow.svg"

function SidebarNav() {
  const [collapsed, setCollapsed] = useState(false)
  const [isMobileMenuShowing, setIsMobileMenuShowing] = useState(false)
  const [isMobileScreenWidth, setIsMobileSrceenWidth] = useState(false)

  const windowWidth = isBrowser === true ? window.innerWidth : null

  useEffect(() => {
    if (windowWidth < 800) {
      setIsMobileSrceenWidth(true)
    } else {
      setIsMobileSrceenWidth(false)
    }
  }, [windowWidth])

  const MobileSideNav = ({ data }) => {
    return (
      <aside id="left-sidebar" className={isMobileMenuShowing ? "" : "hide"}>
        <div
          className="menu-btn-wrap"
          onClick={() => setIsMobileMenuShowing(true)}
        >
          <MenuIcon />
        </div>
        <div className="menu-top">
          <div className="logo-wrap">
            <Link to="/">
              <TswToolBoxLogo />
            </Link>
          </div>
          <div
            className="close-wrap"
            onClick={() => setIsMobileMenuShowing(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <nav id="main-nav">
          {data.allStrapiNavigation.nodes[0].items.map((topLevel, key) => {
            return <PrimaryMenuItem topLevel={topLevel} key={key} />
          })}
        </nav>
      </aside>
    )
  }

  //  @TODO: When menu is collapsed, add .menu-collapsed to main page div
  return (
    <StaticQuery
      query={mainNavQuery}
      render={data => (
        <Fragment>
          {isMobileScreenWidth ? (
            <MobileSideNav data={data} />
          ) : (
            <aside id="left-sidebar" className={collapsed ? "collapsed" : ""}>
              <div className="logo-wrap">
                <Link to="/">
                  <TswToolBoxLogo />
                </Link>
              </div>
              <nav id="main-nav">
                {data.allStrapiNavigation.nodes[0].items.map(
                  (topLevel, key) => {
                    return <PrimaryMenuItem topLevel={topLevel} key={key} />
                  }
                )}
              </nav>
              <div className="collapse-side-nav">
                <div
                  className="collapse-wrap"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <OpenIcon />
                </div>
              </div>
            </aside>
          )}
        </Fragment>
      )}
    />
  )
}

export default SidebarNav

const mainNavQuery = graphql`
  query MainNavQuery {
    allStrapiNavigation {
      nodes {
        name
        slug
        items {
          id
          master
          menuAttached
          order
          path
          title
          type
          related {
            _xcontentType
            id
          }
          items {
            id
            title
            type
            parent
            order
            path
            menuAttached
            items {
              id
              master
              menuAttached
              order
              parent
              path
              title
              type
              related {
                _xcontentType
                id
              }
              items {
                id
                path
                title
                type
                related {
                  _xcontentType
                  id
                }
              }
            }
            related {
              id
              _xcontentType
            }
          }
        }
      }
    }
  }
`
